<template>
  <div class="all-height">
   <div class="">
      <v-card loader-height="2" :loading="loading" :disabled="loading">
        <v-card-text class="">
          <div>
            <div class="d-flex align-center mb-4">
              <v-btn color="primary" class="shadow-off" @click="addeditrudialog=true;addeditrudata={};addeditruerror={}"><v-icon>mdi-plus</v-icon> Add New</v-btn>
              <div style="max-width:400px; min-width:300px" class="ml-4"><lb-string v-model="searchtext" label="Search" hidedetails rounded/></div>
              <v-spacer></v-spacer>
              <v-btn class="shadow-off" text @click="refreshData()"><v-icon left>mdi-refresh</v-icon>Refresh</v-btn>
            </div>
              <v-simple-table dense>
                <thead>
                  <tr>
                    <th scope="slno" class="text-center">Sl No</th>
                    <th scope="period">Name</th>
                    <th scope="period" class="text-center">Currency</th>
                    <th scope="total" class="text-center">Control Threshold Amt</th>
                    <th scope="pending" class="text-center">Control Threshold %</th>
                    <th scope="pendingapproval" class="text-center">Mngt Threshold Amt</th>
                    <th scope="approved" class="text-center">Mngt Threshold %</th>
                    <th scope="rejected" class="text-center">Submitter(s)</th>
                    <th scope="nodata" class="text-center">Approver(s)</th>
                    <th scope="nodata" class="text-center">Manager(s)</th>
                    <th scope="nodata" class="text-center">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr class="hoverable cursor-pointer" v-for="(v,k) in filtereddata" :key="k">
                    <td class="text-center">{{k+1}}</td>
                    <td>{{v.name}}</td>
                    <td class="text-center">{{v.currency}}</td>
                    <td class="text-center">{{$nova.formatNumber(v.control_thresholds_amount)}}</td>
                    <td class="text-center">{{$nova.formatNumber(v.control_thresholds_percent*100)}}%</td>
                    <td class="text-center">{{$nova.formatNumber(v.management_thresholds_amount)}}</td>
                    <td class="text-center">{{$nova.formatNumber(v.management_thresholds_percent*100)}}%</td>
                    <td class="text-center py-1">
                      <div class="d-flex justify-center">
                        <v-tooltip bottom content-class="tooltip-bottom" v-for="(vv,kk) in v.submitter.slice(0, 1)" :key="kk">
                          <template v-slot:activator="{ on, attrs }">
                            <v-avatar size="35" class="mr-1" :style="`background-color:${(userlist[vv] || {}).color || 'rgba(0,0,0,0.2)'}`" v-bind="attrs" v-on="on">
                              {{(((userlist[vv] || {}).name || "UN").replaceAll(" ", "").split("") || []).slice(0, 2).join('').toUpperCase()}}
                            </v-avatar>
                          </template>
                          <span>{{((userlist[vv] || {}).name || "Unknown User")}}</span>
                        </v-tooltip>
                        <v-avatar size="35" v-if="v.submitter.length > 2" class="mr-1 border-on">
                          +{{v.submitter.length - 1}}
                        </v-avatar>
                      </div>
                    </td>
                    <td class="text-center py-1">
                      <div class="d-flex justify-center">
                        <v-tooltip bottom content-class="tooltip-bottom" v-for="(vv,kk) in v.approver.slice(0, 1)" :key="kk">
                          <template v-slot:activator="{ on, attrs }">
                            <v-avatar size="35" class="mr-1" :style="`background-color:${(userlist[vv] || {}).color || 'rgba(0,0,0,0.2)'}`" v-bind="attrs" v-on="on">
                              {{(((userlist[vv] || {}).name || "UN").replaceAll(" ", "").split("") || []).slice(0, 2).join('').toUpperCase()}}
                            </v-avatar>
                          </template>
                          <span>{{((userlist[vv] || {}).name || "Unknown User")}}</span>
                        </v-tooltip>
                        <v-avatar size="35" v-if="v.approver.length > 2" class="mr-1 border-on">
                          +{{v.approver.length - 1}}
                        </v-avatar>
                      </div>
                    </td>
                    <td class="text-center py-1">
                      <div class="d-flex justify-center">
                        <v-tooltip bottom content-class="tooltip-bottom" v-for="(vv,kk) in v.manager.slice(0, 1)" :key="kk">
                          <template v-slot:activator="{ on, attrs }">
                            <v-avatar size="35" class="mr-1" :style="`background-color:${(userlist[vv] || {}).color || 'rgba(0,0,0,0.2)'}`" v-bind="attrs" v-on="on">
                              {{(((userlist[vv] || {}).name || "UN").replaceAll(" ", "").split("") || []).slice(0, 2).join('').toUpperCase()}}
                            </v-avatar>
                          </template>
                          <span>{{((userlist[vv] || {}).name || "Unknown User")}}</span>
                        </v-tooltip>
                        <v-avatar size="35" v-if="v.manager.length > 2" class="mr-1 border-on">
                          +{{v.manager.length - 1}}
                        </v-avatar>
                      </div>
                    </td>
                    <td class="text-center">
                      <v-tooltip bottom content-class="tooltip-bottom">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn @click="addeditrudialog=true;addeditrudata=$nova.deepCopyJson(v);addeditruerror={}" v-bind="attrs" v-on="on" small icon><v-icon>mdi-pencil</v-icon></v-btn>
                        </template>
                        <span>Edit</span>
                      </v-tooltip>
                    </td>
                  </tr>
                </tbody>
              </v-simple-table>
          </div>
        </v-card-text>
      </v-card>

      <v-dialog v-model="addeditrudialog" max-width="600" persistent>
          <v-card :loading="addeditruloading" :disabled="addeditruloading" loader-height="2" class="">
            <v-card-text class="primary white--text d-flex align-center py-2 px-4">
              <span class="subtitle-1 white--text" v-if="addeditrudata._id">Edit Reporting Unit</span>
              <span class="subtitle-1 white--text" v-else>Add Reporting Unit</span>
              <v-spacer></v-spacer>
              <v-btn icon @click="addeditrudialog = false" class="ml-2 white--text"><v-icon>mdi-close</v-icon></v-btn>
            </v-card-text>
            <v-card-text class="mt-4 pt-4">
              <v-row>
                <v-col cols="8" class="my-0 py-1">
                  <lb-string v-model="addeditrudata.name" label="Name" :error="addeditruerror.name"/>
                </v-col>
                <v-col cols="4" class="my-0 py-1">
                  <lb-string v-model="addeditrudata.currency" label="Currency" :error="addeditruerror.currency" :min="$nova.formatDateISO(new Date())"/>
                </v-col>
                <v-col cols="6" class="my-0 py-1">
                  <lb-number v-model="addeditrudata.control_thresholds_amount" min="0" :value="addeditrudata.control_thresholds_amount || '0'" label="Control Thresholds Amount" :error="addeditruerror.control_thresholds_amount"/>
                </v-col>
                <v-col cols="6" class="my-0 py-1">
                  <lb-number v-model="addeditrudata.control_thresholds_percent" min="0" :value="addeditrudata.control_thresholds_percent || '0'" label="Control Thresholds Percent" :error="addeditruerror.control_thresholds_percent"/>
                </v-col>
                <v-col cols="6" class="my-0 py-1">
                  <lb-number v-model="addeditrudata.management_thresholds_amount" min="0" :value="addeditrudata.management_thresholds_amount || '0'" label="Management Thresholds Amount" :error="addeditruerror.management_thresholds_amount"/>
                </v-col>
                <v-col cols="6" class="my-0 py-1">
                  <lb-number v-model="addeditrudata.management_thresholds_percent" min="0" :value="addeditrudata.management_thresholds_percent || '0'" label="Management Thresholds Percent" :error="addeditruerror.management_thresholds_percent"/>
                </v-col>
                <v-col cols="12" class="my-0 pt-1">
                  <div class="body-2 font-weight-bold">
                    Submitter(s): 
                    <v-tooltip bottom content-class="tooltip-bottom">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn @click="existinguserlist=addeditrudata.submitter;addusertype='Submitter';updateuserdialog=true" v-bind="attrs" v-on="on" icon small><v-icon>mdi-plus-circle</v-icon></v-btn>
                      </template>
                      <span>Add Submitter</span>
                    </v-tooltip>
                  </div>
                  <div>
                    <v-chip class="mr-1 mb-1 pr-0" small v-for="(v,k) in addeditrudata.submitter" 
                      :style="`background-color:${(userlist[v] || {}).color || 'rgba(0,0,0,0.2)'}`" :key="k">
                      {{(userlist[v] || {}).name || "Unknown User"}}
                      <v-btn small icon class="ml-1" @click="addeditrudata.submitter.splice(k,1)"><v-icon>mdi-close-circle</v-icon></v-btn>
                    </v-chip>
                  </div>
                </v-col>
                <v-col cols="12" class="my-0">
                  <div class="body-2 font-weight-bold">
                    Approver(s): 
                    <v-tooltip bottom content-class="tooltip-bottom">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn v-bind="attrs" v-on="on" icon small><v-icon>mdi-plus-circle</v-icon></v-btn>
                      </template>
                      <span>Add Approver</span>
                    </v-tooltip>
                  </div>
                  <div>
                    <v-chip class="mr-1 mb-1 pr-0" small v-for="(v,k) in addeditrudata.approver" 
                      :style="`background-color:${(userlist[v] || {}).color || 'rgba(0,0,0,0.2)'}`" :key="k">
                      {{(userlist[v] || {}).name || "Unknown User"}}
                      <v-btn small icon class="ml-1" @click="addeditrudata.approver.splice(k,1)"><v-icon>mdi-close-circle</v-icon></v-btn>
                    </v-chip>
                  </div>
                </v-col>
                <v-col cols="12" class="my-0">
                  <div class="body-2 font-weight-bold">
                    Manager(s): 
                    <v-tooltip bottom content-class="tooltip-bottom">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn v-bind="attrs" v-on="on" icon small><v-icon>mdi-plus-circle</v-icon></v-btn>
                      </template>
                      <span>Add Manager</span>
                    </v-tooltip>
                  </div>
                  <div>
                    <v-chip class="mr-1 mb-1 pr-0" small v-for="(v,k) in addeditrudata.manager" 
                      :style="`background-color:${(userlist[v] || {}).color || 'rgba(0,0,0,0.2)'}`" :key="k">
                      {{(userlist[v] || {}).name || "Unknown User"}}
                      <v-btn small icon class="ml-1" @click="addeditrudata.manager.splice(k,1)"><v-icon>mdi-close-circle</v-icon></v-btn>
                    </v-chip>
                  </div>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-text class="d-flex">
              <v-spacer></v-spacer>
              <v-btn small color="primary" @click="addeditRu()">{{(addeditrudata._id)?'Update':'Add'}}</v-btn>
            </v-card-text>
          </v-card>
        </v-dialog>
      
        <v-dialog v-model="updateuserdialog" max-width="400" persistent>
          <v-card class="">
            <v-card-text class="primary white--text d-flex align-center py-2 px-4">
              <span class="subtitle-1 white--text" v-if="addeditrudata._id">Update {{addusertype}}</span>
              <v-spacer></v-spacer>
              <v-btn icon @click="updateuserdialog = false" class="ml-2 white--text"><v-icon>mdi-close</v-icon></v-btn>
            </v-card-text>
            <v-card-text class="mt-4 pt-4">
              <v-row>
                <v-col cols="12" class="my-0 py-1">
                  <lb-dropdown v-model="existinguserlist" :multiple="true" :items="userlistarr" itemvalue="_id" itemname="name" label="Users"/>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-text class="d-flex">
              <v-spacer></v-spacer>
              <v-btn small color="primary">Update</v-btn>
            </v-card-text>
          </v-card>
        </v-dialog>

    </div>
  </div>
</template>

<script>
export default {
  name: 'reportingunits',
  data: function(){
    return {
      loading: false,
      data: [],
      userlist: {},
      userlistarr: {},
      searchtext: "",
      addeditrudialog: false,
      addeditruloading: false,
      addeditrudata: {},
      addeditruerror: {},
      updateuserdialog: false,
      addusertype: "",
      existinguserlist: [],
    }
  },
  activated() {
    this.refreshData();
  },
  mounted() {
    this.refreshData();
  },
  methods: {
    refreshData() {
      this.getData();
    },
    getData() {
      this.loading = true;
      this.axios.post("/v2/varianceanalysis/reportingunit/getformdata").then(dt => {
        if(dt.data.status === "success") {
          let ndt = dt.data.data[0] || {};
          this.userlist = {};
          this.userlistarr = ndt.users || [];
          let colors = this.$nova.getColors(this.userlistarr.length, 0.3);
          for (let i = 0; i < this.userlistarr.length; i++) {
            const el = this.userlistarr[i];
            this.userlist[el._id] = el;
            this.userlist[el._id].color = colors[i];
          }
          console.log(this.userlist, this.userlistarr);
          return this.axios.post("/v2/varianceanalysis/reportingunit/get")
        }
        else throw new Error ("Error reading data");
      }).then(dt => {
        if(dt.data.status === "success") {
          this.data = dt.data.data;
        }
        else throw new Error ("Error reading data");
      }).catch(err => {
        this.$store.commit("sbError", err.message || err || "Unknown error!");
        console.log(err);
      }).finally(() => {
        this.loading = false;
      })
    },
    addeditRu() {

    },
  },
  computed: {
    filtereddata: function(){
      if(!this.searchtext) return this.data;
      else{
        let ndt = [];
        for (const i of this.data) {
          if((i.name).replaceAll(" ", "").toLowerCase().indexOf(this.searchtext) > -1) ndt.push(i); 
        }
        return ndt;
      }
    }
  },
}
</script>
